@import "./variables";
@import "./mixins";

.chart-legend {
  height: 100%;
  padding-left: 15px;
  padding-bottom: 20px;
  overflow-y: auto !important;
  @include hideTheScrollbar;

  .chart-legend__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    background-color: #F5F7F8;
    border-radius: 2px;
    margin-top: 4px;
    font-weight: 500;
    color: $darkBlueColor;
    cursor: pointer;
    user-select: none;
    border-left: 10px solid transparent;
    &:first-of-type {
      margin-top: 0;
    }
    &:hover {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
    }
    &.deactivated {
      text-decoration: line-through;
      opacity: .7;
    }
  }
}
