@import "./assets/scss/variables";
@import "./assets/scss/mixins";

.appContent {
  padding: 0 $generalPaddingRight 20px calc(#{$sideBarWidth} + 25px);
  height: calc(100vh - #{$headerHeight});
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: 0;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    opacity: 0;
  }
  &.blurred {
    @include useBlur;
  }
}
