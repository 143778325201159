@import "../Tabs.module";

.table {
  .tableItem {
    width: calc(100% / 4);
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }

    .logo {
      margin-left: auto;
      border: 1px solid $darkColor;
    }
  }
}
