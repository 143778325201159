@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/tooltip";

$columnHeight: 30px;

.table {
  position: relative;
  @include tableWrapper;
  padding: 20px $tableRightPadding 65px 30px;
  min-height: 636px;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.header {
      margin-bottom: 10px;

      .headerName {
        color: $darkBlueColor;
        line-height: 16px;
        text-align: center;
      }
    }
    &.total {
      background-color: #E6F6FF;
      border-radius: 3px;
      height: 42px;
      .column {
        font-size: .857rem;
        &:first-of-type {
          padding-left: 25px;
        }
      }
    }
    &.value {
      background-color: #FFFDF5;
      &:last-of-type {
        .column {
          border-bottom: 0;
        }
      }
      &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        background-color: #F5F7F8;
        .column {
          border-bottom: 0;
        }
      }

      .column {
        height: $columnHeight;
        border-bottom: 1px solid $grayColor;
        font-size: .857rem;
        &:first-of-type {
          font-weight: 600;
          color: #000;
          justify-content: left;
        }
      }
    }
    &.rowExpand {
      flex-wrap: wrap;

      > .column {
        border-bottom: 2px solid #FFCE00;
      }
    }
  }

  .column {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $tablesColor;
    width: calc((100% - 150px) / 11);
    flex-shrink: 0;
    padding: 0 10px;
    &:first-of-type {
      width: 150px;
      justify-content: left;
    }

    .userMail {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .user {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 17px;
      height: calc(#{$columnHeight} - 7px);
      width: calc(#{$columnHeight} - 7px);
      background-color: #D1D1D1;
      margin: auto;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E7F6FF;
      user-select: none;
      cursor: default;
      &.userAlign {
        left: 0;
        right: 0;
        margin: auto;
      }

      span {
        color: #656565;
        font-weight: 200;
        text-transform: uppercase;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .userTooltip {
    .userTooltipImg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .userTooltipInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 7px;
      &:first-of-type {
        margin-top: 0;
      }

      span:first-of-type {
        font-weight: 600;
      }

      span:last-of-type {
        padding: 3px 0;
      }
    }
  }

  .expand {
    width: 14px;
    height: 14px;
    background-color: $tablesColor;
    mask: url("../../../assets/imgs/arrow_down.svg") no-repeat center center;
    mask-size: contain;
    transition: all $generalAnimationDuration;
    margin: -3px 7px 0 0;
    will-change: margin-top, transform;
    &.active {
      transform: rotate3d(1, 0, 0, 180deg);
      margin-top: 3px;
    }
  }

  .expandSection {
    width: 100%;
    height: 0;
    overflow: hidden;
    &.active {
      height: auto;
      padding: 5px 0;
    }
  }
}
