@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.wrapper {
  @include tableWrapper;
  max-width: 470px;
  height: 100%;
  width: 100%;
  display: block;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 27px;
    margin-top: 2px;
    &:first-of-type {
      margin-top: 0;
    }
    &:hover {
      .itemName {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
        border-radius: 2px;
      }
      .itemValue {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
        border-radius: 2px;
      }
      .itemUnit {
        box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
        border-radius: 2px;
      }
    }

    .itemName {
      display: flex;
      align-items: center;
      font-size: 1.071rem;
      width: 100%;
      padding-left: 7px;
      background-color: $sideBarBackgroundColor;
      height: 100%;
      color: $blueColor;
    }

    .itemValue {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50px;
      font-size: 1.071rem;
      padding-right: 10px;
      background-color: $sideBarBackgroundColor;
      height: 100%;
      color: #000000;
    }

    .itemUnit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 100%;
      font-size: 1.071rem;
      color: #000000;
      margin-left: 2px;
      user-select: none;
      pointer-events: none;
      background-color: $sideBarBackgroundColor;
    }
  }
}
