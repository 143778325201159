@import "../../assets/scss/variables";

.adminPanel {
  padding-top: 30px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    .tabs {
      margin-top: -4px;

      .tab {
        position: relative;
        display: inline-block;
        font-size: 1.571rem;
        color: #99AFC6;
        font-weight: 600;
        margin-left: 38px;
        transition: color $generalAnimationDuration;
        cursor: pointer;
        user-select: none;
        &::before {
          display: block;
          content: '';
          position: absolute;
          bottom: -13px;
          left: 0;
          height: 3px;
          background-color: $blueColor;
          width: 0;
          border-radius: 0 1px 1px 0;
          transition: width $generalAnimationDuration;
        }
        &:first-of-type {
          margin-left: 0;
        }
        &.active, &:hover {
          color: $darkColor;
          &::before {
            width: 44.9%;
          }
        }
      }
    }

    .headerBtn {
      background-color: $blueColor;
      height: 37px;
      border-radius: 18.5px;
      min-width: 174px;
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
      font-size: 1.143rem;
    }
  }
}
