$fontSize: 14px;
$fontFamily: "Roboto";
$blueColor: #3594E6;
$darkBlueColor: #0356A7;
$yellowColor: #FFCE00;
$darkColor: #444444;
$errColor: #FF3737;
$generalAnimationDuration: .3s;
$generalAnimationDurationLongest: .6s;
$generalPaddingRight: 135px;
$tablesColor: #000;
$grayColor: #DDDDDD;

$headerHeight: 70px;

$sideBarWidth: 110px;
$sideBarBackgroundColor: #F5F7F8;

$sidebarZIndex: 2;
$headerZIndex: 4;
$popupZIndex: 3;

$tableRightPadding: 38px;
$tableItemHeight: 44px;

$chartsRowHeight: 243px;
