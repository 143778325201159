@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/tooltip";

.notifications {
  @include tableWrapper;
  position: relative;
  max-width: 1170px;
  max-height: 812px;
  width: 100%;
  height: 100%;
  padding: 55px 26px 15px;
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .closeBtn {
    position: absolute;
    top: 26px;
    right: 26px;
    width: 16px;
    height: 16px;
    background: url("../../assets/imgs/close__icon.svg") no-repeat center;
    background-size: cover;
  }

  .notificationsList {
    @include hideTheScrollbar;
    overflow-y: auto;
    height: 100%;
  }

  .notification {
    background-color: #F5F7F8;
    border-radius: 26px;
    padding: 7px 29px 7px 7px;
    margin-top: 15px;
    &:first-of-type {
      margin-top: 0;
    }

    .notificationHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 19px;

      .user {
        display: flex;
        align-items: center;

        .userImg {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .info {
          .userName {
            color: #2E3338;
            font-size: 1.143rem;
            font-weight: 600;
          }

          .created {
            color: #B7B8B8;
            font-size: .857rem;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;

        .actionBtn {
          font-size: 1.143rem;
          text-transform: uppercase;
          color: #fff;
          background-color: $blueColor;
          height: 25px;
          border-radius: 18.5px;
          padding: 2px 16px;
          margin-right: 20px;
          &.reject {
            background-color: $errColor;
          }
        }

        .actionDelete {
          width: 16px;
          height: 16px;
          background: url("../../assets/imgs/delete__icon.svg") no-repeat center;
          background-size: cover;
        }
      }
    }

    .notifReport {
      padding: 0 142px 0 26px;

      .created {
        color: #2E3338;
        margin-bottom: 26px;

        .update {
          color: #049815;
          font-weight: 600;
        }
        .remove {
          color: $errColor;
          font-weight: 600;
        }
      }

      .userNameInfo {
        font-style: italic;
        font-weight: 500;
      }

      .items {
        display: flex;
        flex-wrap: wrap;

        .column {
          width: calc(100% / 3);
          padding-left: 35px;
          &:first-of-type {
            padding-left: 0;
          }
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;

          .itemName {
            width: 160px;
            color: $darkColor;
          }

          .itemValue {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 18px;
            border-radius: 29px;
            border: 1px solid transparent;
            background-color: #fff;
            font-size: .857rem;
            color: $darkColor;
            user-select: none;
            cursor: default;
            &.changed {
              border-color: $errColor;
            }
          }

          .itemValueRating {
            position: relative;
            font-size: .929rem;
            font-weight: 600;

            .rateChanged {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              right: -15px;
              width: 12px;
              height: 12px;
              margin: auto;
              background: url("../../assets/imgs/exclamation__icon.svg") no-repeat center;
              background-size: cover;
            }
          }
        }
      }

      .textArea {
        .textAreaTitle {
          color: $darkColor;
          margin-bottom: 10px;
        }

        .textAreaText {
          height: 65px;
          border-radius: 9px;
          background-color: #fff;
        }
      }
    }
  }

  .noData {
    padding: 100px 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    opacity: .5;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}
