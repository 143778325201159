@import "../../assets/scss/variables";
@import "../../assets/scss/charts";

.dashboard {
  padding-top: 27px;

  .dashboardLastNotes {
    .dashboardLastNotesTitle {
      color: $darkBlueColor;
      font-weight: 600;
      font-size: 1.571rem;
      margin-bottom: 5px;
    }
  }
}
