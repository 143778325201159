@import "../Tabs.module";

.table {
  .tableRow {
    &.tableHeader {
      .tableItem {
        &:last-child {
          padding-right: 25px;
        }
      }
    }
  }

  .tableItem {
    width: calc(100% / 6);
    text-align: center;

    .logo {
      margin: 0 auto;
      border: 1px solid $darkColor;
    }

    .noLogo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;
      margin: 0 auto;
      background-color: #D1D1D1;
      font-weight: 300;
      font-size: 1.357rem;
      color: #656565;
      text-transform: uppercase;
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      width: 90px;
      height: 20px;
      border-radius: 10px;
      margin-left: auto;
      cursor: pointer;
      &.active {
        color: #4ACF8A;
        background-color: #DFF6ED;
      }
      &.blocked {
        color: #CF4A4A;
        background-color: #F6DFDF;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        pointer-events: none;
      }
    }
  }
}
