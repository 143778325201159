@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";
@import "../../../assets/scss/forms";

$timeRangeElsHeight: 21px;
$timeRangeBtnMarginLeft: 23px;

.filters {
  @include tableWrapper;
  border-radius: 21.5px;
  padding: 8px 14px;
  height: 45px;
  transition: height $generalAnimationDuration;
  margin-bottom: 48px;
  &.increased {
    height: 303px;

    .header {
      .moreFiltersBtn {
        &::after {
          transform: rotate(180deg) translate(0, -1px);
        }
      }

      .applyBtn {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .timeRange {
      display: flex;
      align-items: center;

      .pickerWrapper {
        margin-left: 13px;
        &:first-of-type {
          margin-left: 0;
          margin-right: 13px;
        }
      }

      .timeRangePicker {
        display: block;
        background-color: #E1EDFE;
        width: 112px;
        height: $timeRangeElsHeight;
        border-radius: 29px;
        color: $darkColor;
        font-size: .929rem;
        padding-left: 10px;
        cursor: pointer;
        &.empty {
          background-image: url("../../../assets/imgs/calendar__icon.svg");
          background-repeat: no-repeat;
          background-position: 90% 50%;
        }
      }

      .moreFiltersBtn {
        position: relative;
        color: $darkColor;
        font-weight: 500;
        margin-left: $timeRangeBtnMarginLeft;
        padding-right: 16px;
        &::after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 10px;
          height: 6px;
          margin: auto;
          background: url("../../../assets/imgs/arrow_down.svg") no-repeat center;
        }
      }

      .quickFiltersList {
        li {
          display: inline-flex;

          button {
            padding: 3px 11px;
            background-color: $blueColor;
            text-transform: uppercase;
            font-size: .929rem;
            font-weight: 500;
            color: #fff;
            border-radius: 18.5px;
            box-shadow: none;
            border: 0;
            margin-left: 23px;
          }
        }
      }
    }

    .export {
      display: flex;
      align-items: center;
      height: 27px;
      border-radius: 14px;
      border: 1px solid $blueColor;
      padding: 2px 12px;

      .exportBtn {
        height: 17px;
        width: 38px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: .929rem;
        background-color: $blueColor;
        border-radius: 4px;
        margin-left: 6px;
        &:first-of-type {
          margin-left: 0;
        }
        &[disabled] {
          opacity: .5;
        }
      }
    }
  }

  .applyBtn {
    background-color: $yellowColor;
    border-radius: 29px;
    height: $timeRangeElsHeight;
    width: 83px;
    color: $darkColor;
    text-transform: uppercase;
    margin-left: $timeRangeBtnMarginLeft;
    transition: opacity $generalAnimationDuration;
    &[disabled] {
      opacity: .5;
      background-color: rgba(68, 68, 68, .5);
      cursor: default;
    }
    &.applyBtnIncreased {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .content {
    display: flex;
    overflow: hidden;
    height: 222px;
    padding-top: 24px;
    transition: height $generalAnimationDuration;

    .area {
      max-width: 376px;
      flex: 1 0 auto;
      margin-left: 40px;
      &:first-of-type {
        margin-left: 0;
      }

      .areaSearch {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .areaSearchTitle {
          color: #99AFC6;
          font-weight: 600;
          margin-right: 7px;
          flex: 1 1 auto;
        }

        .areaSearchField {
          color: $darkColor;
          font-weight: 300;
          border-bottom: 1px solid #99AFC6;
          background: url("../../../assets/imgs/search__icon.svg") no-repeat 100% 50%;
          flex: 1 1 auto;
          width: 100%;
        }

        .areaSearchReset {
          color: $darkColor;
          margin-left: 9px;
          flex: 1 1 auto;
          &[disabled] {
            color: #C8C8C8;
            font-weight: 300;
          }
        }
      }

      .areaList {
        margin-top: 7px;
        list-style: none;
        background-color: $sideBarBackgroundColor;
        border-radius: 17px;
        height: 174px;
        overflow-y: auto;
        padding: 10px 8px 10px 14px;
        @include hideTheScrollbar;
        &.areaListCheckbox {
          height: 151px;
        }

        li {
          color: $darkColor;
          margin-top: 7px;
          cursor: pointer;
          padding: 2px 6px;
          &:first-of-type {
            margin-top: 0;
          }
          &:hover {
            font-weight: 600;
          }
          &.active {
            font-weight: 600;
            background-color: #EEEEEE;
            border-radius: 2px;
            background-image: url("../../../assets/imgs/checkmark__icon.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 13px) 50%;
          }
        }
      }
    }

    .fieldCheckbox {
      height: 23px;
      display: inline-flex;
      align-items: center;
      transform: translate(0, 5px);

      p {
        transform: translate(0px, -1px);
      }
    }
  }
}
