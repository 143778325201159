@import "../../assets/scss/editors";

.editor {
  .formWrapper {
    .form {
      .img {
        display: flex;
        padding: 16px 0 0;
      }
    }
  }
}
