.iconSvgDashboard {
  mask: url("../imgs/dashboard_icon.svg") no-repeat center;
}
.iconSvgAnalytics {
  mask: url("../imgs/analytics_icon.svg") no-repeat center;
}
.iconSvgRating {
  mask: url("../imgs/rating_icon.svg") no-repeat center;
}
.iconSvgAdmin {
  mask: url("../imgs/admin_icon.svg") no-repeat center;
}
.iconSvgSettings {
  mask: url("../imgs/settings_icon.svg") no-repeat center;
}
.iconSvgArrowLeft {
  mask: url("../imgs/arrow_left_icon.svg") no-repeat center;
}
