.tooltip {
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  &::after {
    border-top: 1px solid #C4C4C4 !important;
    border-right: 0 !important;
    border-left: 1px solid #C4C4C4 !important;
    height: 10px;
    background: #fff;
    width: 10px;
    left: 0;
    right: 0;
    bottom: -6px;
    transform: rotate(-135deg);
    margin: auto;
  }
}
