@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/informations";

.table {
  @include tableWrapper;

  .tableItem {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: $tableItemHeight;
    border-radius: 2px;
    &:first-of-type {
      margin-bottom: 6px;
      &:hover {
        .tableItemName, .tableItemValue {
          box-shadow: none;
        }
      }

      .tableItemName {
        border-bottom: 2px solid $yellowColor;
        background-color: transparent;
        margin-top: 0;
      }

      .tableItemValue {
        color: #656565;
        font-weight: 600;
        font-size: 1rem;
        line-height: 16px;
        border-bottom: 2px solid $yellowColor;
        background-color: transparent;
        margin-top: 0;
        &:last-of-type {
          border-bottom-color: $blueColor;
          background-color: transparent;
          &::before {
            display: none;
          }
        }

        span {
          font-size: inherit;
          max-width: 110px;
        }
      }
    }
    &:last-of-type {
      border-bottom: 0;
    }
    &:nth-child(2) {
      .tableItemValue {
        &:last-of-type {
          &::before {
            display: none;
          }
        }
      }
    }
    &:hover, &.active {
      .tableItemName {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
      }
      .tableItemValue {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
        &:last-of-type {
          box-shadow: none;
        }
      }
    }
    &.active {
      .tableItemName {
        border-bottom: 2px solid $yellowColor;
      }

      .tableItemValue {
        border-bottom: 2px solid $yellowColor;
        &:last-of-type {
          border-bottom: 0;
        }
      }

      .tableItemActions {
        align-items: baseline;
        padding-top: calc(#{$tableItemHeight} / 2);
      }
    }

    > p {
      height: $tableItemHeight;
      color: $tablesColor;
    }

    .tableItemName {
      display: flex;
      align-items: center;
      width: 250px;
      font-size: 1.143rem;
      color: $blueColor;
      font-weight: 500;
      background-color: #FFFDF5;
      margin-top: 6px;
      padding-left: 10px;
      flex: 1 1 auto;
    }

    .tableItemValue {
      position: relative;
      @include alignFlexCenter;
      width: calc((100% - 270px - 6px) / 8);
      padding: 0 10px;
      text-align: center;
      font-size: 1.143rem;
      background-color: #FFFDF5;
      margin-top: 6px;
      flex: 1 1 auto;
      &:last-of-type {
        background-color: #E7F6FF;
        font-weight: 500;
        margin-left: 6px;
        &::before {
          position: absolute;
          top: -8px;
          left: 0;
          right: 0;
          display: block;
          content: '';
          width: 100%;
          height: 8px;
          background-color: #E7F6FF;
        }
      }
      &:nth-last-of-type(2) {
        padding-right: 40px;
        width: calc((100% - 110px - 6px) / 8);
      }
    }

    .tableItemActions {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: $tableRightPadding;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .expand {
        width: 14px;
        height: 14px;
        background-color: $tablesColor;
        mask: url("../../../assets/imgs/arrow_down.svg") no-repeat center center;
        mask-size: contain;
        transition: all $generalAnimationDuration;
        &.active {
          transform: rotate3d(1, 0, 0, 180deg);
          mask-position: 50% 25%;
        }
      }
    }

    .hiddenTable {
      width: 100%;
      background-color: #FFFDF5;
      max-height: 0;
      overflow-y: auto;
      opacity: 0;
      transition: max-height $generalAnimationDuration, opacity $generalAnimationDurationLongest;
      will-change: max-height, opacity;
      @include hideTheScrollbar;
      &.active {
        max-height: 250px;
        opacity: 1;
      }

      .tableItem {
        margin-bottom: 1px;
        margin-top: -3px;
        &:first-of-type {
          margin-bottom: 0;

          .tableItemValue {
            font-size: 1.143rem;
          }
        }
        &:last-of-type {
          .tableItemName {
            box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          }
          .tableItemValue {
            box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
            &:nth-last-of-type(2) {
              box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
            }
          }
        }

        &:hover {
          .tableItemValue {
            &:last-of-type {
              box-shadow: none;
            }
          }
        }

        .tableItemName {
          display: flex;
          padding-left: 15px;
          box-shadow: none;
          margin-top: 0;
          border-bottom: 0;

          .tableItemNameContainer {
            color: $tablesColor;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: inherit;
          }
        }

        .tableItemValue {
          color: $tablesColor;
          font-weight: 400;
          box-shadow: none;
          margin-top: 0;
          border-bottom: 0;
          &:last-of-type {
            background-color: #E7F6FF;
            &::before {
              display: block;
              height: 12px;
              top: -12px;
            }
          }
          &:nth-last-of-type(2) {
            box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
          }
        }
      }
    }
  }
}
