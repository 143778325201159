.noData {
  padding: 100px 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  opacity: .5;
  user-select: none;
  pointer-events: none;
}
