@import "variables";

.fieldCheckbox {
  display: flex;
  align-items: center;
  color: $darkColor;
  font-weight: 300;
  cursor: pointer;
  margin-left: 50px;
  user-select: none;
  &:first-of-type {
    margin-left: 0;
  }
  &.disabled {
    cursor: default;
    opacity: .5;
    text-decoration: line-through;
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    &:checked ~ .checkerCheckbox {
      &::before {
        display: block;
      }
    }
  }

  .checkerCheckbox {
    position: relative;
    display: inline-block;
    width: 9px;
    height: 9px;
    border: 1px solid #D6D6D6;
    border-radius: 1px;
    margin-right: 7px;
    &::before {
      position: absolute;
      display: none;
      content: '';
      width: 10px;
      height: 8px;
      top: -2px;
      left: 1px;
      background: url("../../assets/imgs/checkbox-checked__icon.svg") no-repeat;
    }
  }
}
