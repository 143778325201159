.super-toast {
  .Toastify__toast {
    border-radius: 6px;
    min-height: 45px;
    font-family: "Roboto";
    letter-spacing: .01rem;
    &.Toastify__toast--default {
      cursor: default;
    }
  }
}
