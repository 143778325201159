@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/tooltip";

.report {
  @include tableWrapper;
  padding: 20px 48px 26px;
  min-width: 300px;
  max-width: 730px;
  width: 100%;
  min-height: 200px;

  .reportHeader {
    display: flex;
    align-items: center;
    margin-bottom: 43px;

    .reportHeaderTitle {
      font-size: 1.286rem;
      font-weight: 600;
      text-align: center;
      margin-right: 23px;
    }

    .reportHeaderDate {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      max-width: 117px;
      background-color: $yellowColor;
      background-image: url("../../assets/imgs/calendar__icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 9px) 50%;
      height: 23px;
      border-radius: 29px;
      padding: 3px 12px;
      font-size: 0.929rem;
      color: $darkColor;
      box-shadow: none;
      border: 0;
      cursor: pointer;
    }
  }

  .reportContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .reportContentColumn {
      flex: 1 1 auto;
      max-width: 270px;
    }

    .reportField {
      position: relative;
      display: block;
      margin-bottom: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &.reportFieldErr {
        input {
          border-color: $errColor;
        }
      }

      .reportFieldFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span {
        width: 100%;
        max-width: 168px;
        flex-shrink: 0;
        font-size: 1.286rem;
        color: $darkColor;
        text-transform: capitalize;
      }

      input {
        width: 100%;
        max-width: 90px;
        flex-shrink: 0;
        height: 23px;
        background-color: #F5F7F8;
        border-radius: 29px;
        box-shadow: none;
        border: 1px solid #F5F7F8;
        padding: 3px 10px;
        text-align: center;
        font-size: 0.929rem;
        color: $darkColor;
      }

      .reportFieldErrMsg {
        position: absolute;
        font-size: 0.857rem;
        color: $errColor;
      }
    }

    .fieldTitle {
      color: $darkColor;
      font-size: 1.286rem;
    }

    .radioSelect {
      position: relative;
      margin-top: 22px;
      margin-bottom: 30px;

      .radioSelectList {
        padding-top: 0;
      }

      .radioSelectColumn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 13px;
      }

      .radioSelectValue {
        font-size: .929rem;
        font-weight: 600;
        margin: 0;
        transform: translate(0, -1px);
        max-width: 95px;
        text-align: right;
      }

      .reportFieldErrMsg {
        position: absolute;
        font-size: 0.857rem;
        color: $errColor;
      }
    }

    .radioSelectList {
      padding-top: 4px;

      .radioSelectListItem {
        display: inline-flex;
        margin-left: 4px;
        align-items: center;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          .radioSelectName {
            margin-right: 0;
          }
        }

        .radioSelectListLabel {
          display: flex;
          align-items: center;

          input {
            opacity: 0;
            position: absolute;
            z-index: -1;
            &:checked ~ .radioSelectListDot {
              &::before {
                display: block;
              }
            }
          }

          .radioSelectName {
            font-size: .929rem;
            color: $darkColor;
            margin-left: 12px;
            margin-right: 14px;
          }

          .radioSelectListDot {
            position: relative;
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: black;
            cursor: pointer;
            &::before {
              display: none;
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background-color: #fff;
              width: 50%;
              height: 50%;
              border-radius: 50%;
              margin: auto;
            }
          }
        }
      }
    }

    .singleLabel {
      label {
        font-size: 1.286rem;
        line-height: 21px;
        color: $darkColor;
      }
    }

    .fullField {
      padding-top: 14px;
      width: 100%;

      .fullFieldArea {
        background-color: #F5F7F8;
        border-radius: 11px;
        height: 86px;
        width: 100%;
        resize: none;
        border: none;
        box-shadow: none;
        padding: 3px 10px;
        font-size: 0.929rem;
        color: $darkColor;
        &:focus {
          outline: 0;
        }
      }
    }
  }

  .reportBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .reportBottomBtn {
      @include saveBtnStyles;
      margin-left: 14px;
      &:first-of-type {
        margin-left: 0;
      }
      &.cancel {
        background-color: $blueColor;
        color: #fff;
      }
      &.delete {
        background-color: $errColor;
        color: #fff;
      }
    }
  }
}
