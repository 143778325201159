@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.rating {
  padding-top: 30px;

  .title {
    color: $darkBlueColor;
    font-size: 1.571rem;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .wrapper {
    @include tableWrapper;
    padding: 0;

    .header {
      .overall {
        background-color: #1682C5;
        height: 177px;
        display: flex;
        align-items: center;
        justify-content: center;

        .overallName {
          font-size: 5.429rem;
          color: #fff;
          font-weight: 500;
          margin-right: 70px;
        }

        .overallRate {
          display: flex;
          align-items: center;
          transform: translate(0, -5px);

          .rateStar {
            display: block;
            width: 74px;
            height: 74px;
            mask: url("../../../assets/imgs/star__icon.svg") no-repeat center;
            mask-size: cover;
            background-color: #005A96;
            margin-left: 11px;
            &:first-of-type {
              margin-left: 0;
            }
            &.active {
              background-color: #fff;
            }
          }
        }
      }
    }

    .rateTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EEEEEE;
      height: 50px;
      font-size: 1.429rem;
      color: $darkBlueColor;
      font-weight: 500;
    }

    .sections {
      display: flex;
      flex-wrap: wrap;

      .sectionItem {
        width: calc(100% / 4 - 3px);
        margin-left: 4px;
        margin-bottom: 4px;
        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        .sectionRate {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: #F5F7F8;
          height: 117px;

          .sectionRateValue {
            font-size: 3.429rem;
            font-weight: 500;
            color: inherit;
          }

          .sectionRateName {
            font-size: 1.714rem;
            font-weight: 500;
            color: inherit;
          }
        }
      }

      .ratingSection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .ratingSectionItem {
          width: calc(100% / 3 - 2.7px);
          margin-left: 4px;
          &:first-of-type {
            margin-left: 0;
          }

          .rateTitle {
            height: 40px;
            font-size: 1.286rem;
          }

          .ratingSectionValue {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.714rem;
            font-weight: 600;
            height: 75px;
            background-color: #F5F7F8;
          }
        }
      }
    }
  }

  .noData {
    padding: 100px 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    opacity: .5;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}
