@import "../../assets/scss/variables";

.tooltipChart {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  margin: 0;
  background-color: #fff;
  transform: translate(-50%, -12px);
  transition: opacity $generalAnimationDuration, left $generalAnimationDuration, top $generalAnimationDuration;
  pointer-events: none;
  opacity: 0;
  border: 1px solid $darkColor;
  border-radius: 4px;
  padding: 4px 5px;
  will-change: top, left, opacity;
  &::after {
    display: block;
    content: '';
    position: absolute;
    border-left: 1px solid $darkColor;
    border-top: 1px solid $darkColor;
    height: 10px;
    background: #fff;
    width: 10px;
    left: 0;
    right: 0;
    bottom: -6.4px;
    transform: rotate(-135deg);
    margin: auto;
  }

  .tooltipTime {
    font-size: .9rem;
    color: $darkColor;
  }

  .tooltipValue {
    font-size: .9rem;
    color: $darkColor;
    font-weight: 500;
  }
}
